import React, {Component} from 'react';
import './App.scss';
import video from './video.mp4';
import videoMobile from './video_mobile.mp4';
import videoNoSound from './video-nosound.mp4';
import importateurOfficielVideo from "./import.gif";
import logoMba from "./logo-MBA.svg";
import line from "./line-red.svg";
import BrandGrid from "./brand-grid/BrandGrid";
import DistributorSlider from "./distributor-slider/DistributorSlider";
import fleche from "./fleche.svg";
import UserAgent from "@quentin-sommer/react-useragent/es/UserAgent";
import {UserAgentProvider} from '@quentin-sommer/react-useragent';
import footer from "./bord-footer.png";
import UAParser from "ua-parser-js";
import axios from "axios";
import footerBack from "./footer.png";
import distributeur from "./ditributeur.gif";

const redLine = <img src={line} className="littleLine" alt="simple line to separate text"/>;

class App extends Component {

    state = {
        page: "",
        sendingForm: false,
        formMessage: null,
        currentSize: "desktop",
        video: videoNoSound
    };

    constructor() {
        super();
        let page = window.location.pathname;
        this.state.page = page.substr(1);
        this.uaParser = new UAParser()
    }

    goTo = (page) => () => {
        this.setState({page});
        window.scrollTo(0, 0);
        window.history.pushState({}, document.title, page === "" ? "/" : page);
    };

    updateDimensions = () => {
        let size = this.state.currentSize;
        if (size === "mobile" && window.matchMedia("(min-width: 1000px)").matches) {
            this.setState({video: videoNoSound, currentSize: "desktop"})
        } else if (size === "desktop" && window.matchMedia("(max-width: 1001px)").matches) {
            this.setState({video: videoMobile, currentSize: "mobile"})
        }
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
        try{
            const videoElement = document.getElementById("video");
            if(videoElement !== null && video.play !== null){
                videoElement.play();
            }
        } catch(e){
            console.error(e);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    handleContactForm = (e) => {
        e.preventDefault();
        this.setState({sendingForm: true});

        let contactForm = "http://demo.mba-entertainment.com/email.php";
        if (window.location.hostname === "www.mba-entertainment.com") {
            contactForm = "https://www.mba-entertainment.com/email.php";
        }
        if (window.location.hostname === "mba-entertainment.com") {
            contactForm = "https://mba-entertainment.com/email.php";
        }

        const form = document.getElementById("contactForm");
        const finish = () => {
            window.grecaptcha.execute('6LftFo8UAAAAAKzeG1xYrunNPd9Xs0y-So0YhDaP', {action: 'homepage'}).then((token) => {
                form.elements.recaptcha.value = token;
            }, function () {
            });
        };
        window.grecaptcha.execute('6LftFo8UAAAAAKzeG1xYrunNPd9Xs0y-So0YhDaP', {action: 'homepage'}).then((token) => {
            form.elements.recaptcha.value = token;
            axios({
                method: 'post',
                url: contactForm,
                data: new FormData(form),
                config: {headers: {'Content-Type': 'multipart/form-data'}}
            })
                .then(() => {
                    form.reset();
                    this.setState({
                        sendingForm: false,
                        formMessage: "Votre demande a bien prise en compte et nous ne manquerons pas de revenir vers vous très prochainement."
                    });
                    finish();
                })
                .catch(() => {
                    this.setState({sendingForm: false, formMessage: "Une erreur s'est produite, merci de ressayer plus tard."});
                    finish();
                });
        }, () => {
            this.setState({sendingForm: false, formMessage: "Une erreur s'est produite, merci de ressayer plus tard."});
        });

    };

    render() {
        let page = <div>404 Not Found</div>;
        switch (this.state.page) {
            case "":
                page = App.home();
                break;
            case "mentions":
                page = App.mentions();
                break;
            default:
        }
        return (
            <UserAgentProvider ua={window.navigator.userAgent}>
                <div className="App">
                    <header>
                        <div className="video">
                            <UserAgent ios>
                                {isFuckingIOs => (
                                    isFuckingIOs ?
                                        <span id="video"/> :
                                        //<video autoPlay={true} loop={true} muted={true} controls={true} id="video">
                                        //    <source src={videoNoSound} type="video/mp4"/>
                                        //</video> :
                                        //<img src={videoForIOS} width="100%" height="auto"/> :
                                        <video autoPlay={true} loop={true} muted={true} controls={true} id="video">
                                            <source src={this.state.video} type="video/mp4"/>
                                        </video>)}
                            </UserAgent>
                        </div>
                        <div className="content">
                            <div className="logo">
                                <img src={logoMba} alt="logo de mba entertainment"/>
                            </div>
                            <div className="text">
                                <h2>Votre partenaire</h2>
                                <h1>Divertissement</h1>
                                {redLine}
                                <p>Créée en 1996 et leader français actuel dans l’importation et
                                    la distribution d’attractions et appareils automatiques,
                                    nos produits sont présents chez plus de <b>1.000 partenaires
                                        en France.</b></p>
                            </div>
                        </div>
                    </header>
                    {
                        page
                    }
                    <footer>
                        <div className="headImage">
                            <img src={footerBack} alt="lignes curves"/>
                        </div>
                        <div className="background">
                            <div className="content">
                                <div className="text">
                                    <h1>Nous Contacter</h1>
                                    {redLine}
                                    <p>65 Chemin du Moulin Carron,<br/> 69570 Dardilly</p>
                                    <p><a href="mailto:mba-dardilly@wanadoo.fr">mba-dardilly@wanadoo.fr</a></p>
                                    <p>Tél. <a href="tel:+33437496868">+33 (0) 4 37 49 68 68</a></p>
                                    <div className="logo">
                                        <img src={logoMba} alt="logo de mba entertainment"/>
                                    </div>
                                </div>
                                <div className="contactForm">
                                    <form onSubmit={this.handleContactForm} id="contactForm">
                                        <input type="text" placeholder="Nom *" name="name" required={true}/>
                                        <input type="text" placeholder="Société" name="company"/>
                                        <input type="text" placeholder="Ville" name="city"/>
                                        <input type="email" placeholder="Email *" name="email" required={true}/>
                                        <input type="hidden" value={window.recaptchaToken} name="recaptcha"/>
                                        <textarea name="message" placeholder="Message *" required={true} rows={10}/>
                                        <label className="rgpd"><input type="checkbox" className="rgpd" required={true}/> En poursuivant, vous acceptez de vous
                                            conformer à notre <a href="/mentions"><u>Politique de protection des données</u></a>. (requis)</label>
                                        <div className="endForm">
                                            <button type="submit" disabled={this.state.sendingForm}>
                                                <img src={fleche} alt="Envoyer la demande"/>
                                                Envoyer votre demande
                                            </button>
                                            <div className="textLeg">Champs obligatoires *</div>
                                        </div>
                                        {
                                            this.state.formMessage !== null ?
                                                <div>
                                                    {this.state.formMessage}
                                                </div> : []
                                        }
                                    </form>
                                </div>
                            </div>
                            <div className="footerBar">
                                <img src={footer} alt="pied de page"/>
                                <div className="links">
                                    <div onClick={this.goTo("")}>MBA ENTERTAINEMENT</div>
                                    <div onClick={this.goTo("mentions")}>Mentions légales</div>
                                </div>
                                <img src={footer} alt="pied de page"/>
                            </div>
                        </div>
                    </footer>
                </div>
            </UserAgentProvider>
        );
    }

    static home() {
        return (<div className="bodyContent">
            <div className="games">
                <div className="text">
                    <h1>Notre sélection de jeux</h1>
                    {redLine}
                    <p>Nos connexions à travers le monde, nous permettent d’être
                        toujours à l’avant-garde des nouveautés de l’industrie du
                        loisirs. Notre professionnalisme, notre expérience et
                        l’expertise de notre service technique reconnus de tous sont
                        des gages de notre pérennité et de notre sérieux.</p>
                    <p className="bolder">Quelque soit votre secteur d’activité, nous avons l’attraction qui vous convient !</p>
                    <img src={importateurOfficielVideo} alt="Importateur Officiel"/>
                </div>
                <BrandGrid/>
            </div>
            <div className="distributor">
                <div className="text">
                    <div>
                        <img src={distributeur} alt="Distributeur" className="distribGif"/>
                    </div>
                    <p>Stern Pinball, Inc. («SPI») est le plus ancien
                        concepteur et fabricant de jeux de flipper
                        de qualité. De 1930 à aujourd’hui, Stern
                        Pinball continue fièrement de promouvoir
                        son jeu convivial et accessible à tous.</p>
                </div>
                <DistributorSlider/>
            </div>
        </div>)
    }

    static mentions() {
        return (<div className="bodyContent">
            <div className="mentions">
                <h1>MENTIONS LEGALES</h1>
                <br/><br/>
                {redLine}
                <br/>
                <h2>Editeur du site</h2>
                <p>
                    MBA (SAS)<br/>
                    Numéro de SIRET : 409 845 476<br/>
                    Responsable éditorial : MBA (SAS)<br/>
                    65 Chemin du Moulin Carron, 69570 Dardilly<br/>
                    Téléphone : 04 37 49 68 68<br/>
                    Email : mba-dardilly@wanadoo.com<br/>
                    Site Web : www.mba-entertainment.com
                </p>
                <h2>Hébergement</h2>
                <p>
                    Hébergeur : Ovh<br/>
                    77 Boulevard Marius Vivier Merle, 69003 Lyon<br/>
                    Site Web : www.ovh.com
                </p>
                <h2>Développement</h2>
                <p>
                    Agence Monet et Associés<br/>
                    Adresse : 32, quai Arloing 69009 Lyon<br/>
                    Site Web : www.monet-rp.com
                </p>
                <h2>Conditions d'utilisation</h2>
                <p>Ce site www.mba-entertainment.com est proposé en différents langages web (HTML, HTML5, Javascript, CSS, etc…) pour un meilleur confort
                    d’utilisation et un graphisme plus agréable, nous vous recommandons de recourir à des navigateurs modernes comme Internet explorer, Safari,
                    Firefox, Google Chrome, etc…
                    Monet et Associés met en œuvre tous les moyens dont elle dispose, pour assurer une information fiable et une mise à jour fiable de ses sites
                    internet. Toutefois, des erreurs ou omissions peuvent survenir. L’internaute devra donc s’assurer de l’exactitude des informations et
                    signaler toutes modifications du site qu’il jugerait utile. Monet et Associés n’est en aucun cas responsable de l’utilisation faite de ces
                    informations, et de tout préjudice direct ou indirect pouvant en découler.</p>
                <p>Cookies : Le site www.mba-entertainment.com peut être amené à vous demander l’acceptation des cookies pour des besoins de statistiques et
                    d’affichage. Un cookie est une information déposée sur votre disque dur par le serveur du site que vous visitez. Il contient plusieurs
                    données qui sont stockées sur votre ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des
                    informations. Certaines parties de ce site ne peuvent être fonctionnelles sans l’acceptation de cookies.</p>
                <p>Liens hypertextes : Les sites internet peuvent offrir des liens vers d’autres sites internet ou d’autres ressources disponibles sur Internet.
                    Monet & Associés ne dispose d’aucun moyen pour contrôler les sites en connexion avec ses sites internet, et ne répond pas de la
                    disponibilité de tels sites et sources externes, ni ne la garantit. Elle ne peut être tenue pour responsable de tout dommage, de quelque
                    nature que ce soit, résultant du contenu de ces sites ou sources externes, et notamment des informations, produits ou services qu’ils
                    proposent, ou de tout usage qui peut être fait de ces éléments. Les risques liés à cette utilisation incombent pleinement à l’internaute,
                    qui doit se conformer à leurs conditions d’utilisation.</p>
                <p>Les utilisateurs, les abonnés et les visiteurs des sites internet ne peuvent mettre en place un hyperlien en direction de ce site sans
                    l’autorisation expresse et préalable de Monet & Associés et de MBA.</p>
                <p>Dans l’hypothèse où un utilisateur ou visiteur souhaiterait mettre en place un hyperlien en direction d’un des sites internet de Monet &
                    Associés et de MBA, il lui appartiendra d’adresser un email accessible sur le site afin de formuler sa demande de mise en place d’un
                    hyperlien. Monet & Associés et MBA se réservent le droit d’accepter ou de refuser un hyperlien sans avoir à en justifier sa décision.</p>
                <p>Services fournis : L’ensemble des activités de la société ainsi que ses informations sont présentés sur notre site
                    www.mba-entertainment.com.</p>
                <p>Les renseignements figurant sur le site www.mba-entertainment.com ne sont pas exhaustifs et les photos non contractuelles. Ils sont donnés
                    sous réserve de modifications ayant été apportées depuis leur mise en ligne. Par ailleurs, toutes les informations indiquées sur le site
                    www.mba-entertainment.com sont données à titre indicatif, et sont susceptibles de changer ou d’évoluer sans préavis.</p>
                <p>Limitations contractuelles sur les données :</p>
                <p>Les informations contenues sur ce site sont aussi précises que possible et le site remis à jour à différentes périodes de l’année, mais peut
                    toutefois contenir des inexactitudes ou des omissions. Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement,
                    merci de bien vouloir le signaler par email, à l’adresse mba-dardilly@wanadoo.com, en décrivant le problème de la manière la plus précise
                    possible (page posant problème, type d’ordinateur et de navigateur utilisé, …).</p>
                <p>Tout contenu téléchargé se fait aux risques et périls de l’utilisateur et sous sa seule responsabilité. En conséquence, ne saurait être tenu
                    responsable d’un quelconque dommage subi par l’ordinateur de l’utilisateur ou d’une quelconque perte de données consécutives au
                    téléchargement. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec
                    un navigateur de dernière génération mis-à-jour</p>
                <p>Les liens hypertextes mis en place dans le cadre du présent site internet en direction d’autres ressources présentes sur le réseau Internet
                    ne sauraient engager la responsabilité de Monet & Associés.</p>
                <p>Propriété intellectuelle :</p>
                <p>Tout le contenu du présent sur le site www.mba-entertainment.com, incluant, de façon non limitative, les graphismes, images, textes, vidéos,
                    animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société à l’exception des marques,
                    logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs.</p>
                <p>Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est
                    strictement interdite sans l’accord exprès par écrit de Monet & Associés et MBA. Cette représentation ou reproduction, par quelque procédé
                    que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Le
                    non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre,
                    les propriétaires des contenus copiés pourraient intenter une action en justice à votre encontre.</p>
                <p>Déclaration à la CNIL :</p>
                <p>Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi 2004-801 du 6 août 2004 relative à la protection des personnes physiques à
                    l’égard des traitements de données à caractère personnel) relative à l’informatique, aux fichiers et aux libertés, ce site n’a pas fait
                    l’objet d’une déclaration auprès de la Commission nationale de l’informatique et des libertés (www.cnil.fr).</p>
                <p>Litiges :</p>
                <p>Les présentes conditions du site www.mba-entertainment.com sont régies par les lois françaises et toute contestation ou litiges qui
                    pourraient naître de l’interprétation ou de l’exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège
                    social de la société. La langue de référence, pour le règlement de contentieux éventuels, est le français.</p>
                <p>Données personnelles :</p>
                <p>De manière générale, vous n’êtes pas tenu de nous communiquer vos données personnelles lorsque vous visitez notre site Internet
                    www.mba-entertainment.com.</p>
                <p>Cependant, ce principe comporte certaines exceptions. En effet, pour certains services proposés par notre site, vous pouvez être amenés à
                    nous communiquer certaines données telles que : votre nom, votre fonction, le nom de votre société, votre adresse électronique, et votre
                    numéro de téléphone. Tel est le cas lorsque vous remplissez le formulaire qui vous est proposé en ligne. Dans tous les cas, vous pouvez
                    refuser de fournir vos données personnelles. Dans ce cas, vous ne pourrez pas utiliser les services du site, notamment celui de solliciter
                    des renseignements sur notre société, ou de recevoir les lettres d’information.</p>
                <p>Enfin, nous pouvons collecter de manière automatique certaines informations vous concernant lors d’une simple navigation sur notre site
                    Internet, notamment : des informations concernant l’utilisation de notre site, comme les zones que vous visitez et les services auxquels
                    vous accédez, votre adresse IP, le type de votre navigateur, vos temps d’accès. De telles informations sont utilisées exclusivement à des
                    fins de statistiques internes, de manière à améliorer la qualité des services qui vous sont proposés. Les bases de données sont protégées
                    par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des
                    bases de données.</p>
            </div>
        </div>)
    }
}

export default App;
