import React from "react";
import mario from "./mario.png";
import maze from "./maze.png";
import transformers from "./transformers.png";
import overtake from "./overtake.png";
import bandai from "./bandai.png";
import sega from "./sega.png";
import wahlap from "./wahlap.png";
import triotech from "./triotech.png";
import overGame from "./over-jeu.svg";
import overBrand from "./over-logo.svg";

export default class BrandGrid extends React.Component {

    state = {
        elements: {
            games: [
                {
                    name: "Mario",
                    url: "https://www.youtube.com/watch?time_continue=1&v=kvieKatNxLI",
                    resource: mario,
                    positions: {desktop: {x: 500, y: 0}, mobile: {x: 10, y: 215}}
                },
                {
                    name: "Transformers",
                    url: "https://www.segaarcade.com/games/transformers-shadows-rising.html",
                    resource: transformers,
                    positions: {desktop: {x: 500, y: 230}, mobile: {x: 10, y: 435}}
                },
                {
                    name: "Maze",
                    url: "https://trio-tech.com/products/vr-maze/",
                    resource: maze,
                    positions: {desktop: {x: 695, y: 115}, mobile: {x: 197, y: 325}}
                },
                {
                    name: "Overtake",
                    url: "http://en.wahlap.com/index.php?m=content&c=index&a=show&catid=52&id=92",
                    resource: overtake,
                    positions: {desktop: {x: 695, y: 345}, mobile: {x: 197, y: 545}}
                },
            ],
            brands: [
                {
                    name: "Sega",
                    url: "https://www.segaarcade.com/",
                    resource: sega,
                    positions: {desktop: {x: 370, y: 360}, mobile: {x: 197, y: 10}}
                },
                {
                    name: "Bandai",
                    url: "http://www.bandainamco-am.co.uk/",
                    resource: bandai,
                    positions: {desktop: {x: 370, y: 205}, mobile: {x: 320, y: 83}}
                },
                {
                    name: "Wahlap",
                    url: "http://en.wahlap.com/",
                    resource: wahlap,
                    positions: {desktop: {x: 243, y: 280}, mobile: {x: 197, y: 155}}
                },
                {
                    name: "Triotech",
                    url: "https://trio-tech.com/",
                    resource: triotech,
                    positions: {desktop: {x: 115, y: 205}, mobile: {x: 75, y: 83}}
                },
            ]
        },
        size: {
            desktop: {width: 950, height: 600},
            mobile: {width: 480, height: 800},
        },
        currentSize: "desktop"
    };

    render() {
        let size = this.state.currentSize;
        if (window.matchMedia("(min-width: 920px)").matches) {
            size = "desktop"
        }

        return (<div className="brands">
            <svg
                preserveAspectRatio="xMidYMid meet"
                viewBox={"0 0 " + this.state.size[size].width + " " + this.state.size[size].height}
            >
                {
                    this.state.elements.games.map(value => <g key={value.name} className="growMe game" onClick={() => window.open(value.url, "_blank")}>
                        <image xlinkHref={value.resource} x={value.positions[size].x} y={value.positions[size].y} height="273" width="240"/>
                        <image xlinkHref={overGame} x={value.positions[size].x} y={value.positions[size].y} height="273" width="240" className="over"/>
                    </g>)
                }
                {
                    this.state.elements.brands.map(value => <g key={value.name} className="growMe brand" onClick={() => window.open(value.url, "_blank")}>
                        <image xlinkHref={value.resource} x={value.positions[size].x} y={value.positions[size].y} height="177" width="155"/>
                        <image xlinkHref={overBrand} x={value.positions[size].x} y={value.positions[size].y} height="177" width="155" className="over"/>
                    </g>)
                }
            </svg>
        </div>)

    }

    updateDimensions = () => {
        let size = this.state.currentSize;
        if (size === "mobile" && window.matchMedia("(min-width: 920px)").matches) {
            this.setState({currentSize: "desktop"})
        } else if (size === "desktop" && window.matchMedia("(max-width: 920px)").matches) {
            this.setState({currentSize: "mobile"})
        }
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }


}
